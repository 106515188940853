<template>
  <div class="index">
    <div class="index-banner">
      <!-- <picture>
        <source
          media="(min-width: 1100px)"
          :srcset="`${$Static}/src/static/pages/index/banner.jpg`"
        />
        <img src="" />
      </picture> -->
      <div class="banner">
        <img src="https://static.gogowork.cn/src/static/pages/index/banner2.jpg" alt="" style="max-width:1200px">
      </div>
      <div class="index-banner-text">
        <!-- <div @click="jumpHandler('/home/page')">成为雇主</div>
        <div @click="jumpHandler('task')">我是人才</div> -->
      </div>
    </div>
    <div class="index-client">
      <div class="client-content" v-for="(item, index) in clientImgSrc" :key="index">
        <img v-if="item.src" v-lazy="item.src" :alt="item.title" />
        <p v-if="!item.src">{{ item.title }}</p>
      </div>
    </div>
    <div class="index-serve">
      <div class="index-serve-title">提供8个主类、141种大类、1787种细化服务</div>
      <div class="index-serve-wrapper">
        <div class="index-serve-wrapper-content" v-for="(item, index) in serveImgSrc" :key="index">
          <div class="serve-img">
            <img v-lazy="item.src" :alt="item.title" />
            <div class="serve-animation" @click="jumpToPage('task', item)">
              <span>查看详情</span>
            </div>
          </div>
          <p class="serve-des">{{ item.title }}</p>
        </div>
      </div>
    </div>
    <div class="index-person">
      <div class="index-person-title">
        <div>
          <p>优秀人才</p>
<!--          <i></i><span>如何成为优秀人才</span>-->
        </div>
<!--        <span @click="fetchPersonLists(page)">换一批</span>-->
      </div>
      <div class="index-person-lists">
        <div class="person-list" v-for="(item, index) in personLists" :key="index">
          <div class="list-wrapper">
            <div class="list-img">
              <img v-lazy="item.avatar" :alt="item.name" />
              <div class="list-img-animation" @click="jumpToPage( 'freelancer', item )">
                <span>查看详情</span>
              </div>
            </div>
            <div class="list-pad">
              <div class="pad-intro">
                <span>{{ item.name }}</span>
                <span>{{ item.hourly_rate }}</span>
              </div>
              <div class="pad-des">
                <span v-for="(skill, idx) in item.skills" :key="idx">{{ skill.name }}</span>
              </div>
              <div class="pad-star">
                <div class="pad-star-left">
                  <isvg icon-class="five-pointed-star"/>
                  <span>{{ item.score }}</span>
                  <span>（{{ item.review_count }}条评价）</span>
                </div>
                <div class="pad-star-left">{{ item.service_count }}个服务</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="index-classify">
      <div class="index-classify-left">
        <img v-lazy="`${this.$Static}/src/static/pages/index/footerimg-01.jpg`" alt="" />
        <div class="text">
          <h1>专业人士随时待命</h1>
          <p>五千万专业人士</p>
          <div @click="jumpHandler('freelancer')">雇佣人才</div>
        </div>
      </div>
      <div class="index-classify-right">
        <img v-lazy="`${this.$Static}/src/static/pages/index/footerimg-02.jpg`" alt="" />
        <div class="text">
          <h1>企业服务</h1>
          <p>以自己的方式寻找人才</p>
          <div @click="jumpHandler('business')">查看更多</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { getPersonLists } from '@/_api/sys/index';

  export default {
    name: 'index',
    data() {
      return {
        clientImgSrc: [
          {
            title: '服务客户',
            src: ''
          },
          {
            title: 'client-1',
            src: `${this.$Static}/src/static/pages/index/logo-01.jpg`
          },
          {
            title: 'client-2',
            src: `${this.$Static}/src/static/pages/index/logo-02.jpg`
          },
          {
            title: 'client-3',
            src: `${this.$Static}/src/static/pages/index/logo-03.jpg`
          },
          {
            title: 'client-4',
            src: `${this.$Static}/src/static/pages/index/logo-04.jpg`
          },
          {
            title: 'client-5',
            src: `${this.$Static}/src/static/pages/index/logo-05.jpg`
          },
          {
            title: 'client-6',
            src: `${this.$Static}/src/static/pages/index/logo-06.jpg`
          }
        ],
        serveImgSrc: [
          {
            title: '客户管理和支持',
            src: `${this.$Static}/src/static/pages/index/serve-support.jpg`,
            cat_id: 65
          },
          {
            title: '财务与会计',
            src: `${this.$Static}/src/static/pages/index/serve-account.jpg`,
            cat_id: 47
          },
          {
            title: '法律服务',
            src: `${this.$Static}/src/static/pages/index/serve-law.jpg`,
            cat_id: 45
          },
          {
            title: '人力资源与培训',
            src: `${this.$Static}/src/static/pages/index/serve-human.jpg`,
            cat_id: 45
          },
          {
            title: '工程与建筑',
            src: `${this.$Static}/src/static/pages/index/serve-engineering.jpg`,
            cat_id: 104
          },
          {
            title: '影视',
            src: `${this.$Static}/src/static/pages/index/serve-film.jpg`,
            cat_id: 113
          },
          {
            title: 'IT开发',
            src: `${this.$Static}/src/static/pages/index/serve-it.jpg`,
            cat_id: 73
          },
          {
            title: '设计和创意',
            src: `${this.$Static}/src/static/pages/index/serve-design.jpg`,
            cat_id: 7
          }
        ],
        page: 1,
        personLists: [],
        maxPage: 0
      };
    },
    created() {
      this.fetchPersonLists(this.page);
    },
    mounted() {
      window.onresize = function () {
        // console.log("宽度", document.documentElement.clientWidth);
        // console.log("高度", document.documentElement.clientHeight);
      };
    },
    methods: {
      async fetchPersonLists(page = this.page) {
        const { data } = await getPersonLists({ page });
        this.maxPage = data?.pages?.page_num;
        this.personLists = data;
        this.page >= this.maxPage ? (this.page = 1) : (this.page += 1);
      },
      jumpHandler(ID) {
        this.$Jump(ID);
      },
      jumpToPage(type, data) {
        switch (type) {
          case 'task':
            this.$router.push({ path: '/task', name: 'taskIndex', params:{id:data.cat_id,name:data.title} });
            break;
          case 'freelancer':
            this.$router.push({ path: '/freelancer/info/'+data.id });
            break;
        }
      }
    }
  };
</script>

<style lang="less" scoped>
  @content-width: 80vw;
  .transition-ease {
    transition: all 0.3s ease;
  }

  .line-center {
    height: 38px;
    line-height: 38px;
    text-align: center;
    border-radius: 5px;
    .pointer;
    padding: 0 40px;
    .font-20;
    font-weight: 700;
    font-size: 16px;
    transition: background-color 0.3s;
  }

  .index {
    &-banner {
      position: relative;

      .banner {
        height: 400px;
        background: url("https://static.gogowork.cn/src/static/pages/index/banner2_bg.jpg");
        // background-color: #423fd8;
        text-align: center;
        background-position: center;
      }

      & > img {
        width: 100vw;
      }

      &-text {
        position: absolute;
        left: 23vw;
        top: 300px;
        .flex;

        & > div {
          &:first-of-type {
            .background-blue;
            .line-center;
            color: @color-white;
          }

          &:first-of-type:hover {
            .background-white;
            .line-center;
            color: @color-blue;
          }

          &:last-of-type {
            margin-left: 16px;
            .background-white;
            .line-center;
            color: @color-blue;
          }

          &:last-of-type:hover {
            .background-blue;
            .line-center;
            color: @color-white;
            // background-color: rgba(255, 255, 255, 0.2);
          }
        }
      }
    }

    &-client {
      border-bottom: 1px solid #030f3b;
      padding: 32px 0;
      margin: auto;
      max-width: @content-width;
      .flex-center-center;

      .client-content {
        flex: 1;

        img {
          height: 82px;
          width: auto;
        }
      }
    }

    &-serve {
      margin: 0 auto;
      padding: 32px 0;
      border-bottom: 1px solid #030f3b;
      max-width: @content-width;

      &-title {
        font-size: 34px;
        color: #474747;
        font-weight: 700;
      }

      &-wrapper {
        display: flex;
        margin-top: 32px;
        flex-wrap: wrap;
        justify-content: space-between;

        &-content {
          padding: 0 12px;
          margin-top: 25px;
          width: 24%;
          .box-border;

          .serve-img {
            width: 100%;
            position: relative;
            transition: all 0.2s ease;
            .pointer;
            overflow: hidden;
            border-radius: 5px;

            .serve-animation {
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              background-color: rgba(0, 0, 0, 0.5);
              color: @color-white;
              .font-16;
              opacity: 0;
              .transition-ease;

              & > span {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate3d(-50%, -50%, 0);
                padding: 10px;
                border: 1px solid @color-white;
                border-radius: 5px;
              }
            }

            &:hover {
              .serve-animation {
                opacity: 1;
              }

              & > img {
                .transition-ease;
                transform: scale(1.2);
                transform-origin: center;
              }
            }
          }

          .serve-des {
            font-size: 24px;
            padding-top: 10px;
            color: #030f3b;
          }
        }
      }
    }

    &-person {
      max-width: @content-width;
      margin: 0 auto;
      padding: 32px 0;

      &-title {
        .flex;
        justify-content: space-between;
        align-items: center;

        & > div {
          .flex;
          align-items: center;

          & > p {
            font-size: 34px;
          }

          & > i {
            height: 16px;
            width: 16px;
            background-size: contain;
            margin-left: 16px;
            .pointer;
          }

          & > span {
            .font-16;
            margin-left: 5px;
          }
        }

        & > span {
          color: @color-blue;
          .pointer;
        }
      }

      &-lists {
        .flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .person-list {
          margin-top: 32px;
          width: 24%;
          .box-border;
          padding: 0 12px;

          .list-wrapper {
            .transition-ease;

            .list-img {
              position: relative;
              transition: all 0.2s ease;
              .pointer;
              overflow: hidden;
              border-radius: 5px;

              &:hover {
                .list-img-animation {
                  opacity: 1;
                }

                & > img {
                  .transition-ease;
                  transform: scale(1.2);
                  transform-origin: center;
                }
              }

              &-animation {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                background-color: rgba(0, 0, 0, 0.5);
                color: @color-white;
                .font-16;
                opacity: 0;
                .transition-ease;

                & > span {
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate3d(-50%, -50%, 0);
                  padding: 10px;
                  border: 1px solid @color-white;
                  border-radius: 5px;
                }
              }
            }

            .list-pad {
              padding: 12px;
              .font-14;

              .pad-intro {
                .flex;
                justify-content: space-between;
                position: relative;
                .border-bottom-1px(1px);
                padding: 5px 0;

                & > span {
                  font-weight: 700;
                }
              }

              .pad-des {
                margin-top: 10px;

                & > span {
                  position: relative;
                }

                & > span + span {
                  margin-left: 10px;
                }

                & > span:not(:first-of-type):after {
                  content: '';
                  position: absolute;
                  height: 15px;
                  top: 0;
                  left: -5px;
                  width: 1px;
                  background-color: #000000;
                }
              }

              .pad-star {
                margin-top: 15px;
                .flex;
                justify-content: space-between;
                align-items: baseline;

                &-left {
                  & > svg {
                    width: 16px;
                    height: 16px;
                    margin-right: 5px;
                  }
                }
              }
            }

            &:hover {
              box-shadow: 10px 10px 16px 0 #b6cdd8;
              transform: translateY(-5px);
            }
          }
        }
      }
    }

    &-classify {
      .flex;

      &-left,
      &-right {
        position: relative;

        .text {
          position: absolute;
          top: 50%;
          left: 25%;
          transform: translate3d(0, -50%, 0);
          color: @color-white;

          & > h1 {
            font-size: 38px;
            font-weight: 700;
          }

          & > p {
            font-size: 31.5px;
            margin-top: 25px;
          }

          & > div {
            .pointer;
            margin-top: 25px;
            width: 125px;
            height: 40px;
            background-color: #fff;
            color: #000000;
            line-height: 40px;
            text-align: center;
            border-radius: 5px;
          }
        }
      }
    }
  }

  @media (min-width: 501px) and (max-width: 1100px) {
    .index-client {
      margin: unset;
      max-width: 100vw;
      justify-content: center;
      .client-content {
        flex: 0.13;
      }
    }

    .index-serve {
      margin: unset;
      max-width: 100vw;
    }

    .index-person {
      margin: unset;
      max-width: 100vw;
    }
  }

  @media (min-width: 100px) and (max-width: 500px) {
    .index-banner-text > div {
      height: 25px !important;
      line-height: 25px !important;
      font-size: 12px !important;
    }

    .index-client {
      margin: unset;
      max-width: 100vw;
      justify-content: center;
      .client-content {
        flex: 0.13;

        img {
          height: 35px !important;
        }
      }
    }

    .index-serve {
      margin: unset;
      max-width: 100vw;
      &-title {
        font-size: 24px !important;
        line-height: 34px;
        display: flex;
        justify-content: center;
      }

      &-wrapper-content .serve-des {
        font-size: 12px;
      }
    }

    .index-person {
      margin: unset;
      max-width: 100vw;
      &-title {
        display: flex;
        justify-content: center;

        div > p {
          font-size: 24px !important;
        }
      }
    }

    .index-person-lists .person-list .list-wrapper .list-pad {
      display: none;
    }

    .index-classify {
      display: block;
      width: 100vw !important;
    }
  }
</style>
